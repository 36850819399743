import React from 'react';
import BackgroundImg from 'gatsby-background-image';
import { Carousel } from 'react-responsive-carousel';
import { Container } from 'react-bootstrap';
import styled from 'styled-components';

import { mediaQueries } from '../../theme/mediaQueries';

const CarouselComponent = ({ carousel }) => {
  // console.log(carousel.reviewExcerptOne);

  return (
    <Carousel showThumbs={false} showStatus={false} infiniteLoop={true}>
      <StyledCarouselSlide key="slide1">
        {carousel.carouselImageOne &&
        carousel.carouselImageOne.asset &&
        carousel.carouselImageOne.asset.fluid ? (
          <BackgroundImg
            fluid={carousel.carouselImageOne.asset.fluid}
            style={{
              height: '100vh',
              backgroundPosition: 'top left',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
            }}
          >
            {carousel.reviewExcerptOne &&
            carousel.reviewExcerptOne._rawExcerpt &&
            carousel.reviewExcerptOne.source ? (
              <StyledCarouselSlideInner>
                <StyledCarouselCaption>
                  <StyledCaptionContainer>
                    <p>{carousel.reviewExcerptOne._rawExcerpt}</p>
                    <cite>{carousel.reviewExcerptOne.source}</cite>
                  </StyledCaptionContainer>
                </StyledCarouselCaption>
              </StyledCarouselSlideInner>
            ) : (
              ''
            )}
          </BackgroundImg>
        ) : (
          ''
        )}
      </StyledCarouselSlide>
      <StyledCarouselSlide key="slide2">
        {carousel.carouselImageTwo &&
        carousel.carouselImageTwo.asset &&
        carousel.carouselImageTwo.asset.fluid ? (
          <BackgroundImg
            fluid={carousel.carouselImageTwo.asset.fluid}
            style={{
              height: '100vh',
              backgroundPosition: 'top left',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
            }}
          >
            {carousel.reviewExcerptTwo &&
            carousel.reviewExcerptTwo._rawExcerpt &&
            carousel.reviewExcerptTwo.source ? (
              <StyledCarouselSlideInner>
                <StyledCarouselCaption>
                  <StyledCaptionContainer>
                    <p>{carousel.reviewExcerptTwo._rawExcerpt}</p>
                    <cite>{carousel.reviewExcerptTwo.source}</cite>
                  </StyledCaptionContainer>
                </StyledCarouselCaption>
              </StyledCarouselSlideInner>
            ) : (
              ''
            )}
          </BackgroundImg>
        ) : (
          ''
        )}
      </StyledCarouselSlide>
      <StyledCarouselSlide key="slide3">
        {carousel.carouselImageThree &&
        carousel.carouselImageThree.asset &&
        carousel.carouselImageThree.asset.fluid ? (
          <BackgroundImg
            fluid={carousel.carouselImageThree.asset.fluid}
            style={{
              height: '100vh',
              backgroundPosition: 'top left',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
            }}
          >
            {carousel.reviewExcerptThree &&
            carousel.reviewExcerptThree._rawExcerpt &&
            carousel.reviewExcerptThree.source ? (
              <StyledCarouselSlideInner>
                <StyledCarouselCaption>
                  <StyledCaptionContainer>
                    <p>{carousel.reviewExcerptThree._rawExcerpt}</p>
                    <cite>{carousel.reviewExcerptThree.source}</cite>
                  </StyledCaptionContainer>
                </StyledCarouselCaption>
              </StyledCarouselSlideInner>
            ) : (
              ''
            )}
          </BackgroundImg>
        ) : (
          ''
        )}
      </StyledCarouselSlide>
    </Carousel>
  );
};

const StyledCarouselSlide = styled.div`
  height: 100vh;
`;

const StyledCarouselSlideInner = styled.div`
  width: 100%;
  height: 100%;
  color: white;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding-bottom: 3rem;
`;

const StyledCarouselCaption = styled.div`
  background-color: rgba(0, 0, 0, 0.25);
  width: 100%;
  padding: 0.75rem 0;
`;

const StyledCaptionContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & p {
    font-family: ${(props) => props.theme.fonts.sans};
    font-size: 1.3rem;
    font-style: italic;
    font-weight: 300;
    line-height: 1.4;

    ${mediaQueries('md')`
      max-width: 70%;
    `};
  }
  & cite {
    font-family: ${(props) => props.theme.fonts.condensed};
    font-size: 1.1rem;
    letter-spacing: 0.05em;
    font-style: normal;
    text-transform: uppercase;
    color: ${(props) => props.theme.colors.primary};
  }
`;

export default CarouselComponent;
