import React from 'react';
import { Link } from 'gatsby';
import BackgroundImg from 'gatsby-background-image';
import styled from 'styled-components';

import { mediaQueries } from '../../theme/mediaQueries';
import { localizeDate } from '../../lib/helpers';

import ViewMoreBtn from '../Buttons/ViewMoreBtn';

const RecordingCard = ({ recording, lang, relatedUrl, btnText }) => {
  const { publishedAt, title, slug, imageOnly } = recording;

  const localeReleaseDate = localizeDate(publishedAt, lang, 'short', null);

  return (
    <StyledLink to={relatedUrl ? `${relatedUrl}${slug.current}` : slug.current}>
      <StyledCard>
        <div style={{ overflow: 'hidden' }}>
          <BackgroundImg
            fluid={imageOnly.asset.fluid}
            title={title}
            alt={`Cover "${title}"`}
            className="cover-bg-image"
          >
            <StyledBgInner />
          </BackgroundImg>
        </div>
        <div style={{ backgroundColor: 'transparent' }}>
          <StyledCardBody>
            <StyledDate as="time">{localeReleaseDate}</StyledDate>
            <StyledTitle>{title}</StyledTitle>
          </StyledCardBody>
          <StyledCardFooter>
            <ViewMoreBtn btnText={btnText} />
          </StyledCardFooter>
        </div>
      </StyledCard>
    </StyledLink>
  );
};

const StyledCardBody = styled.main`
  padding: 0.5rem 0 0;
`;

const StyledBgInner = styled.div`
  width: 100%;

  &::after {
    content: '';
    display: block;
    width: 100%;
    padding-bottom: 100%;
  }
`;

const StyledCard = styled.article`
  border-radius: 0;
`;

const StyledDate = styled.time`
  display: block;
  color: ${(props) => props.theme.colors.primary};
  text-transform: uppercase;
  font-size: 0.75rem;
  font-weight: 400;
  letter-spacing: 0.05rem;
  margin-bottom: 0.5rem;
`;

const StyledTitle = styled.h3`
  font-family: ${(props) => props.theme.fonts.sans};
  font-size: 1.2rem;
  font-weight: 500;
  color: ${(props) => props.theme.colors.black};
  line-height: 1.2;
  margin: 0;
  margin-top: -0.4rem;
`;

const StyledCardFooter = styled.footer`
  background-color: ${(props) => props.theme.colors.transparent};
  border-top: 0;
  border-radius: 0 !important;
  padding: 0;
`;

const StyledLink = styled(Link)`
  display: block;
  & .cover-bg-image {
    transform: scale(1);
    transition: transform 0.2s ease;
  }
  &:hover,
  &:active {
    & .view-more-btn {
      &::before {
        width: 56px;
      }
    }
    & .cover-bg-image {
      transform: scale(1.05);
    }
  }
`;

export default RecordingCard;
