import React from 'react';
import { Link, graphql } from 'gatsby';

import { mapEdgesToNodes } from '../lib/helpers';

import localize from '../components/localize';
import Layout from '../components/layout';
import SEO from '../components/seo';

import CarouselComponent from '../components/HomeSections/CarouselComponent';
import AboutSection from '../components/HomeSections/AboutSection';
import EventsSection from '../components/HomeSections/EventsSection';
import RecordingsSection from '../components/HomeSections/RecordingsSection';
import VideosSection from '../components/HomeSections/VideosSection';
import ReviewsSection from '../components/HomeSections/ReviewsSection';
import GallerySection from '../components/HomeSections/GallerySection';
import BlogSection from '../components/HomeSections/BlogSection';

const IndexPage = ({ data, pageContext, path }) => {
  const {
    siteTitle,
    shareImage,
    _rawDescription: description,
    _rawConsentText: constentText,
  } = data.site;

  // Carousel Section
  const carouselData = mapEdgesToNodes(data.carousel)[0];
  // console.log(carouselData);

  // About Section
  const { title: aboutTitle, heroImg } = data.aboutPage;
  const { _rawBody: aboutContent } = data.aboutText;
  const { readMore } = data.aboutDictionary;

  // Events Section
  const eventsSectionData = mapEdgesToNodes(data.events);
  const { currentEvents, viewAllEvents, viewEvent } = data.eventsDictionary;

  // Videos Section
  const videosTitle = data.videosPage.title;
  const { viewAllVideos, loadMoreVideos, videoNotAvailable } = data.videosDictionary;

  // Blog Section
  const blogTitle = data.blogPage.title;
  const blogSectionData = mapEdgesToNodes(data.blogposts);
  const { viewAllBlogs, viewOneBlog } = data.blogDictionary;

  // Recordings Section
  const recordingsSectionData = mapEdgesToNodes(data.recordings);
  const recordingsTitle = data.recordingsPage.title;
  const { viewAllRecordings, viewRecording, loadMoreRecordings } = data.recordingsDictionary;

  // Reviews Section
  const reviewsSectionData = mapEdgesToNodes(data.reviews);
  const reviewsTitle = data.reviewsPage.title;
  const { viewReviews, readReview, loadMoreReviews } = data.reviewsDictionary;

  // Gallery Section
  const gallerySectionData = mapEdgesToNodes(data.gallery);
  const { viewPhotoGallery } = data.galleryDictionary;

  // console.log(viewPhotoGallery);
  return (
    <Layout pageContext={pageContext} path={path} siteTitle={siteTitle}>
      <SEO
        title=""
        description={description}
        // keywords={keywords}
        imageAlt={shareImage.alt}
        lang={pageContext.locale}
        path={path}
        pageType="website"
      />
      <CarouselComponent carousel={carouselData} />
      <AboutSection
        aboutImg={heroImg ? heroImg.asset.fluid : ''}
        aboutTitle={aboutTitle}
        aboutText={aboutContent}
        path={path}
        btnText={readMore}
      />
      <EventsSection
        events={eventsSectionData}
        path={path}
        lang={pageContext.locale}
        title={currentEvents}
        viewAll={viewAllEvents}
        viewOne={viewEvent}
      />
      <BlogSection
        title={blogTitle}
        blogposts={blogSectionData}
        lang={pageContext.locale}
        viewAll={viewAllBlogs}
        viewOne={viewOneBlog}
      />
      <RecordingsSection
        recordings={recordingsSectionData}
        path={path}
        lang={pageContext.locale}
        title={recordingsTitle}
        viewAll={viewAllRecordings}
        viewOne={viewRecording}
      />
      <VideosSection
        path={path}
        title={videosTitle}
        viewAll={viewAllVideos}
        videoNotAvailable={videoNotAvailable}
      />
      <ReviewsSection
        reviews={reviewsSectionData}
        path={path}
        lang={pageContext.locale}
        title={reviewsTitle}
        viewAll={viewReviews}
        viewOne={readReview}
      />
      <GallerySection images={gallerySectionData} path={path} btnText={viewPhotoGallery} />
    </Layout>
  );
};

export const query = graphql`
  query IndexPageQuery {
    site: sanitySiteSettings {
      siteTitle
      siteUrl
      siteAuthor
      shareImage {
        asset {
          fluid {
            src
          }
        }
        alt {
          _type
          de
          en
        }
      }
      _rawDescription
      _rawConsentText(resolveReferences: { maxDepth: 3 })
      # keywords {
      #   _type
      #   de
      #   en
      # }
    }

    carousel: allSanitySiteCarousel(sort: { fields: _updatedAt, order: DESC }, limit: 1) {
      edges {
        node {
          carouselImageOne {
            asset {
              fluid {
                ...GatsbySanityImageFluid
              }
            }
          }
          reviewExcerptOne {
            _rawExcerpt(resolveReferences: { maxDepth: 1 })
            source
          }
          carouselImageTwo {
            asset {
              fluid {
                ...GatsbySanityImageFluid
              }
            }
          }
          reviewExcerptTwo {
            _rawExcerpt(resolveReferences: { maxDepth: 1 })
            source
          }
          carouselImageThree {
            asset {
              fluid {
                ...GatsbySanityImageFluid
              }
            }
          }
          reviewExcerptThree {
            _rawExcerpt(resolveReferences: { maxDepth: 1 })
            source
          }
        }
      }
    }

    aboutPage: sanityPage(id: { eq: "-4b244c2b-81f1-566a-a75f-51f2c072d0af" }) {
      title {
        _type
        de
        en
      }
      heroImg {
        asset {
          fluid {
            ...GatsbySanityImageFluid
          }
        }
      }
    }
    aboutText: sanityAbout {
      _rawBody(resolveReferences: { maxDepth: 3 })
    }
    aboutDictionary: sanityDictionaryAbout {
      readMore {
        _type
        de
        en
      }
    }

    blogposts: allSanityPost(sort: { fields: publishedAt, order: DESC }, limit: 3) {
      edges {
        node {
          id
          title {
            _type
            de
            en
          }
          headerImage {
            asset {
              fluid {
                ...GatsbySanityImageFluid
              }
            }
          }
          publishedAt
          slug {
            current
          }
          _rawContent(resolveReferences: { maxDepth: 3 })
        }
      }
    }
    blogPage: sanityPage(id: { eq: "-d01d65c9-52f9-5731-8fc7-29b43fd70810" }) {
      title {
        _type
        de
        en
      }
    }
    blogDictionary: sanityDictionaryBlog {
      viewAllBlogs {
        _type
        de
        en
      }
      viewOneBlog {
        _type
        de
        en
      }
      loadMoreBlogs {
        _type
        de
        en
      }
    }

    events: allSanityEvent(sort: { fields: eventDate, order: DESC }) {
      edges {
        node {
          id
          slug {
            current
          }
          title {
            _type
            de
            en
          }
          eventDate
          location
          _rawShortDescription(resolveReferences: { maxDepth: 5 })
        }
      }
    }
    eventsDictionary: sanityDictionaryEvent {
      currentEvents {
        _type
        de
        en
      }
      loadMoreEvents {
        _type
        de
        en
      }
      pastEvents {
        _type
        de
        en
      }
      upcomingEvents {
        _type
        de
        en
      }
      viewAllEvents {
        _type
        de
        en
      }
      viewEvent {
        _type
        de
        en
      }
    }

    videosPage: sanityPage(id: { eq: "-ff3dfa49-5532-56b2-b2cc-b5e0f8704552" }) {
      title {
        _type
        de
        en
      }
    }
    videosDictionary: sanityDictionaryVideo {
      viewAllVideos {
        _type
        de
        en
      }
      loadMoreVideos {
        _type
        de
        en
      }
      viewMoreVideosOnYT {
        _type
        de
        en
      }
      videoNotAvailable {
        _type
        de
        en
      }
    }

    recordings: allSanityRecording(sort: { fields: publishedAt, order: DESC }, limit: 5) {
      edges {
        node {
          id
          title {
            _type
            de
            en
          }
          publishedAt
          slug {
            current
          }
          imageOnly {
            asset {
              fluid {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
      }
    }
    recordingsPage: sanityPage(id: { eq: "-bec422d0-6822-5b98-b1dc-abfd568abc82" }) {
      title {
        _type
        de
        en
      }
    }
    recordingsDictionary: sanityDictionaryRecording {
      viewAllRecordings {
        _type
        de
        en
      }
      viewRecording {
        _type
        de
        en
      }
      loadMoreRecordings {
        _type
        de
        en
      }
    }

    reviews: allSanityReview(sort: { fields: publishedAt, order: DESC }, limit: 3) {
      edges {
        node {
          id
          title {
            _type
            de
            en
          }
          publishedAt
          source
          _rawQuote(resolveReferences: { maxDepth: 5 })
          slug {
            current
          }
        }
      }
    }
    reviewsPage: sanityPage(id: { eq: "-2b9d8163-c1f8-5a3b-845d-4de6425d6303" }) {
      title {
        _type
        de
        en
      }
    }
    reviewsDictionary: sanityDictionaryReview {
      viewReviews {
        _type
        de
        en
      }
      readReview {
        _type
        de
        en
      }
      loadMoreReviews {
        _type
        de
        en
      }
    }
    gallery: allSanityPortrait(
      filter: { displayOnIndex: { eq: true } }
      sort: { fields: shootingDate, order: DESC }
      limit: 6
    ) {
      edges {
        node {
          id
          caption {
            _type
            de
            en
          }
          portrait {
            asset {
              fluid {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
      }
    }
    galleryPage: sanityPage(id: { eq: "-7bdafda6-3a42-590a-afdf-4ee93cd88586" }) {
      title {
        _type
        de
        en
      }
    }
    galleryDictionary: sanityDictionaryGallery {
      viewPhotoGallery {
        _type
        de
        en
      }
    }
  }
`;

export default localize(IndexPage);
