import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Masonry from 'react-masonry-css';
import styled from 'styled-components';

import SectionBgComponent from '../SectionBgComponent';
import OutlineBtnLg from '../Buttons/OutlineBtnLg';
import PageFooter from '../PageFooter';
import PageSectionTitle from '../PageSectionTitle';
import EventCard from '../Event/EventCard';
import { IoTimerOutline } from 'react-icons/io5';

const EventsSection = ({ events, path, lang, title, viewAll, viewOne }) => {
  const sortEvents = (eventsList) => {
    const testDate = new Date('2019-02-22');
    const dateToday = new Date(Date.now());
    let past = [];
    let upcoming = [];

    eventsList.forEach((element) => {
      element.eventDate = new Date(element.eventDate);
      if (element.eventDate < dateToday) {
        past.push(element);
      } else {
        upcoming.push(element);
      }
    });
    return [past, upcoming];
  };
  const sorted = sortEvents(events);

  const pastEvents = sorted[0];
  const upcomingEvents = sorted[1];
  // Show only the next 3 events, starting from closest to current date
  // Reverse display order to show next event first
  const upcomingEventsToShow = upcomingEvents
    .slice(Math.max(upcomingEvents.length - 3, 0))
    .reverse();

  // Responsive breakpoints for Masonry component
  const breakpointColumnObj = {
    default: 3,
    992: 2,
    500: 1,
  };

  const eventsList = upcomingEventsToShow.map((event) => {
    return (
      <EventCard
        key={event.id}
        event={event}
        lang={lang}
        btnText={viewOne}
        relatedUrl={lang === 'en' ? `/${lang}/events/` : `/events/`}
      />
    );
  });

  return (
    <SectionBgComponent bg="white">
      <Container className="pb-4 py-md-4">
        <PageSectionTitle title={title} />
      </Container>
      <Container>
        <Masonry
          breakpointCols={breakpointColumnObj}
          className="my-masonry-grid"
          columnClassName="my-masonry-grid_column"
        >
          {eventsList}
        </Masonry>
      </Container>
      <PageFooter>
        <OutlineBtnLg toUrl={`${path}events`} btnText={viewAll} type="primary" />
      </PageFooter>
    </SectionBgComponent>
  );
};

export default EventsSection;
