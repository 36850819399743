import React from 'react';
import Img from 'gatsby-image';
import BackgroundImg from 'gatsby-background-image';
import { Container, Row, Col, Card } from 'react-bootstrap';
import Masonry from 'react-masonry-css';
import styled from 'styled-components';

import { mediaQueries } from '../../theme/mediaQueries';

import SectionBgComponent from '../SectionBgComponent';
import RecordingCard from '../Recording/RecordingCard';
import PageSectionTitle from '../PageSectionTitle';
import PageFooter from '../PageFooter';
import OutlineBtnLg from '../Buttons/OutlineBtnLg';

const RecordingsSection = ({ recordings, path, lang, title, viewAll, viewOne }) => {
  const recordingsList = recordings.map((recording) => (
    <RecordingCard
      key={recording.id}
      recording={recording}
      btnText={viewOne}
      lang={lang}
      relatedUrl={lang === 'en' ? `/${lang}/recordings/` : `/recordings/`}
    />
  ));
  recordingsList.unshift(
    <StyledTitleSection key="0">
      <PageSectionTitle title={title} />
    </StyledTitleSection>
  );

  const breakpointColumnObj = {
    default: 3,
    992: 2,
    500: 1,
  };

  return (
    <SectionBgComponent>
      <Container className="pt-5 pt-md-5 mt-md-5">
        <Masonry
          breakpointCols={breakpointColumnObj}
          className="my-masonry-grid"
          columnClassName="my-masonry-grid_column"
        >
          {recordingsList}
        </Masonry>
      </Container>
      <PageFooter>
        <OutlineBtnLg btnText={viewAll} toUrl={`${path}recordings`} />
      </PageFooter>
    </SectionBgComponent>
  );
};

const StyledTitleSection = styled.div`
  & > h2 {
    line-height: 0;
  }
  margin-top: -1rem;

  ${mediaQueries('md')`
    margin-top: -2rem;
    padding: 0;
  `}

  ${mediaQueries('lg')`
    margin-top: 0;
    padding: 0;
  `}
  ${mediaQueries('xl')`
    padding: 0 0 0.5rem;
  `}
`;

export default RecordingsSection;
