import React from 'react';
import Img from 'gatsby-image';
import BackgroundImg from 'gatsby-background-image';
import { Container, Row, Col, Card } from 'react-bootstrap';
import styled from 'styled-components';

import { mediaQueries } from '../../theme/mediaQueries';

import OutlineBtnLg from '../Buttons/OutlineBtnLg';
import BlurredBgSection from '../BlurredBgSection';
import PageSectionTitle from '../PageSectionTitle';
import PortableText from '../portableText';

const AboutSection = ({ aboutImg, aboutTitle, aboutText, path, btnText }) => {
  let introText;

  if (aboutImg && aboutTitle && aboutText && btnText) {
    introText = aboutText.slice(0, 1);
    return (
      <StyledSectionContainer>
        <StyledBgContainer>
          <StyledBgImgContainer>
            <StyledBgImg fluid={aboutImg} />
          </StyledBgImgContainer>

          <StyledBgSpacer />
        </StyledBgContainer>

        <StyledOverlayContainer>
          <StyledImgContainer>
            <StyledImg fluid={aboutImg} imgStyle={{ objectPosition: 'top' }} />
          </StyledImgContainer>

          <StyledContentContainer>
            <StyledTextContainer>
              <PageSectionTitle title={aboutTitle} />
              <PortableText blocks={introText} />
              <span style={{ marginTop: '2rem' }}>
                <OutlineBtnLg btnText={btnText} toUrl={`${path}about`} type="black" />
              </span>
            </StyledTextContainer>
          </StyledContentContainer>
        </StyledOverlayContainer>
      </StyledSectionContainer>
    );
  } else if (aboutTitle && aboutText && btnText) {
    introText = aboutText.slice(0, 1);
    return (
      <StyledTextContainer style={{ alignItems: 'center', textAlign: 'justify' }}>
        <PageSectionTitle title={aboutTitle} />
        <PortableText blocks={introText} />
        <span style={{ marginTop: '2rem' }}>
          <OutlineBtnLg btnText={btnText} toUrl={`${path}about`} type="black" />
        </span>
      </StyledTextContainer>
    );
  } else {
    return '';
  }
};
const StyledSectionContainer = styled.div`
  position: relative;
  ${mediaQueries('md')`
    position: relative;
    width: 100%;
  `};
`;
const StyledBgContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  ${mediaQueries('md')`
    flex-direction: row;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  `};
`;
const StyledBgImgContainer = styled.div`
  position: relative;
  width: 100%;
  height: 50vh;
  @media screen and (min-width: 280px) {
    height: 80vh;
  }

  @media screen and (min-width: 460px) {
    height: 85vh;
  }
  @media screen and (min-width: 500px) {
    height: 95vh;
  }

  ${mediaQueries('md')`
    width: 35%; 
    height: 100%;
    position: relative;
  `};
`;
const StyledBgSpacer = styled.div`
  width: 100%;
  ${mediaQueries('md')`
    width: 65%; 
  `};
`;
const StyledBgImg = styled(BlurredBgSection)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  ${mediaQueries('md')`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  `};
`;

const StyledOverlayContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${mediaQueries('md')`
    display: flex; 
    flex-direction: row;
    flex-wrap: no-wrap;
  `};
`;
const StyledImgContainer = styled.div`
  width: 100%;
  padding: 0;
  ${mediaQueries('md')`
    width: 50%; 
  `};
`;
const StyledImg = styled(Img)`
  width: 90%;
  transform: translate(5%, 10%);

  ${mediaQueries('md')`
    width: 80%; 
    height: 80%; 
    transform: translate(15%, 3rem);
  `};
`;
const StyledContentContainer = styled.div`
  ${mediaQueries('md')`
    width: 50%;
  `};
`;
const StyledTextContainer = styled(Container)`
  padding: 5rem 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;

  @media screen and (min-width: 280px) {
    padding: 15rem 1.5rem;
  }
  @media screen and (min-width: 300px) {
    padding: 13rem 1.5rem;
  }
  @media screen and (min-width: 330px) {
    padding: 8rem 1.5rem 2rem;
  }
  @media screen and (min-width: 380px) {
    padding: 5rem 1.5rem 2rem;
  }
  @media screen and (min-width: 700px) {
    padding: 8rem 1.5rem 4rem;
  }

  ${mediaQueries('md')`
    padding: 3rem 2rem;
    padding-right: 15%;
    margin-top: -1.5rem;
    align-items: flex-start;
    justify-content: flex-start; 
  `};

  & > p {
    text-align: justify;
  }
`;

export default AboutSection;
