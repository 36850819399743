import React from 'react';
import { Container } from 'react-bootstrap';
import { Carousel } from 'react-responsive-carousel';
import styled from 'styled-components';

import { breakpoints } from '../../theme/mediaQueries';
import SectionBgComponent from '../SectionBgComponent';
import ReviewCard from '../Review/ReviewCard';
import PageFooter from '../PageFooter';
import OutlineBtnLg from '../Buttons/OutlineBtnLg';
import PageSectionTitle from '../PageSectionTitle';

const ReviewsSection = ({ reviews, path, lang, title, viewAll, viewOne }) => {
  const reviewsCardList = reviews.map((item) => (
    <StyledCardWrapper key={item.id}>
      <ReviewCard
        review={item}
        lang={lang}
        btnText={viewOne}
        relatedUrl={lang === 'en' ? `/${lang}/press/` : `/press/`}
      />
    </StyledCardWrapper>
  ));

  return (
    <SectionBgComponent>
      <Container className="text-center pt-3 pt-md-5 mb-n4 mb-md-0">
        <PageSectionTitle title={title} />
      </Container>
      <Container>
        <StyledReviewsCarousel showStatus={false} infiniteLoop={true} showThumbs={false}>
          {reviewsCardList}
        </StyledReviewsCarousel>
      </Container>
      <PageFooter>
        <OutlineBtnLg
          toUrl={lang === 'en' ? `/${lang}/press/` : `/press/`}
          btnText={viewAll}
          type="black"
        />
      </PageFooter>
    </SectionBgComponent>
  );
};

const StyledCardWrapper = styled.div`
  padding-top: 1rem;
  padding-bottom: 3rem;
  padding-left: 3rem;
  padding-right: 3rem;

  @media (min-width: ${breakpoints.md}px) {
    margin-bottom: 3rem;
  }
`;

const StyledReviewsCarousel = styled(Carousel)`
  margin-left: -15px;
  margin-right: -15px;

  @media (min-width: ${breakpoints.md}px) {
    margin-left: 0;
    margin-right: 0;
  }

  & .carousel .slide {
    background: ${(props) => props.theme.colors.transparent};
    display: flex;
    justify-content: center;
    text-align: left;
  }
  & .carousel .control-dots .dot {
    background: ${(props) => props.theme.colors.primary};
    box-shadow: none;
  }
  & .carousel.carousel-slider .control-arrow {
    color: ${(props) => props.theme.colors.primary};

    &:hover {
      background-color: ${(props) => props.theme.colors.transparent};
    }
  }
  & .carousel .control-arrow::before,
  & .carousel.carousel-slider .control-arrow::before {
    border-top: 8px solid ${(props) => props.theme.colors.transparent};
    border-bottom: 8px solid ${(props) => props.theme.colors.transparent};

    @media (min-width: ${breakpoints.md}px) {
      border-top-width: 16px;
      border-bottom-width: 16px;
    }
  }
  & .carousel .control-prev.control-arrow::before {
    border-right: 8px solid ${(props) => props.theme.colors.primary};

    @media (min-width: ${breakpoints.md}px) {
      border-right-width: 16px;
    }
  }
  & .carousel .control-next.control-arrow::before {
    border-left: 8px solid ${(props) => props.theme.colors.primary};

    @media (min-width: ${breakpoints.md}px) {
      border-left-width: 16px;
    }
  }
`;

export default ReviewsSection;
