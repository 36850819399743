import React from 'react';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import { Card, Container, Row, Col } from 'react-bootstrap';
import styled from 'styled-components';

import { localizeDate } from '../../lib/helpers';
import { breakpoints } from '../../theme/mediaQueries';

import PortableText from '../portableText';
import ViewMoreBtn from '../Buttons/ViewMoreBtn';

const BlogCard = ({ post, lang, color, relatedUrl, btnText }) => {
  const { title, slug, publishedAt, _rawContent: content, headerImage } = post;

  const localePublishingDate = localizeDate(publishedAt, lang, 'long', null);
  // console.log(headerImage);

  if (headerImage) {
    return (
      <StyledLink to={relatedUrl ? `${relatedUrl}${slug.current}` : slug.current}>
        <Container style={{ maxWidth: 'calc(100% + 30px)' }}>
          <StyledBlogCardRow className="blog-card-row">
            <StyledBlogCardColLeft md={6} className="blog-card-left">
              <StyledBlogCardLeft>
                <StyledBlogCardImgBody>
                  <StyledBlogCardImg fluid={headerImage.asset.fluid} />
                </StyledBlogCardImgBody>
              </StyledBlogCardLeft>
            </StyledBlogCardColLeft>
            <StyledBlogCardColRight md={6} className="blog-card-right">
              <StyledBlogCardRight color={color}>
                <StyledBlogCardBody>
                  <Card.Title
                    as="h3"
                    style={{ fontSize: '1.3rem', fontWeight: 400, marginBottom: 0 }}
                  >
                    {title}
                  </Card.Title>
                  <p>
                    <small style={{ fontWeight: 300 }}>{localePublishingDate}</small>
                  </p>
                  <PortableText blocks={content[0]} />
                </StyledBlogCardBody>
                <Card.Footer
                  style={{
                    paddingTop: 0,
                    paddingBottom: '1.25rem',
                    lineHeight: 1,
                    backgroundColor: 'transparent',
                    border: 'none',
                    borderRadius: 0,
                    textAlign: 'left',
                  }}
                >
                  <ViewMoreBtn btnText={btnText} />
                </Card.Footer>
              </StyledBlogCardRight>
            </StyledBlogCardColRight>
          </StyledBlogCardRow>
        </Container>
      </StyledLink>
    );
  } else {
    return (
      <StyledLink to={slug.current}>
        <StyledTextCardContainer color={color}>
          <div>
            <Card.Title as="h3" style={{ fontSize: '1.3rem', fontWeight: 400, marginBottom: 0 }}>
              {title}
            </Card.Title>
            <p>
              <small style={{ fontWeight: 300 }}>{localePublishingDate}</small>
            </p>

            <PortableText blocks={content[0]} />

            <ViewMoreBtn btnText="mehr lesen" />
          </div>
        </StyledTextCardContainer>
      </StyledLink>
    );
  }
};

const StyledLink = styled(Link)`
  display: block;
  color: inherit;
  margin-bottom: 2rem;

  @media (min-width: ${breakpoints.md}px) {
    margin-bottom: 3rem;
  }

  & h3 {
    transition: ${(props) => props.theme.transforms.link};
  }

  &:hover,
  &:active {
    color: inherit;

    & h3 {
      color: ${(props) => props.theme.colors.primary};
    }

    & .view-more-btn {
      color: ${(props) => props.theme.colors.primary};
      &::before {
        width: 56px;
      }
    }
  }
`;

const StyledBlogCardRow = styled(Row)``;

const StyledBlogCardColLeft = styled(Col)`
  padding: 0;
  order: 1;
  z-index: 1;

  @media (min-width: ${breakpoints.md}px) {
    display: flex;
    align-items: center;
  }
`;

const StyledBlogCardColRight = styled(Col)`
  padding: 0;
  order: 2;
  z-index: 2;
  @media (min-width: ${breakpoints.md}px) {
    display: flex;
    align-items: center;
    max-width: 60%;
  }
`;

const StyledBlogCardLeft = styled(Card)`
  border-radius: 0;
  border: none;
  width: 100%;
`;

const StyledBlogCardRight = styled(Card)`
  border-radius: 0;
  border: none;
  background-color: ${(props) =>
    props.color === 'white' ? props.theme.colors.black : props.theme.colors.white};
  color: ${(props) =>
    props.color === 'white' ? props.theme.colors.white : props.theme.colors.black};
  padding: 1.5rem 2rem;
`;

const StyledBlogCardImgBody = styled(Card.Body)`
  padding: 0;
`;

const StyledBlogCardImg = styled(Img)`
  width: 100%;
  max-height: 300px;

  @media (min-width: ${breakpoints.md}px) {
    padding-bottom: 56.25%;
  }
`;

const StyledBlogCardHeader = styled(Card.Header)``;

const StyledBlogCardBody = styled(Card.Body)`
  padding: 1.25rem 1.25rem 0;
`;

const StyledTextCardContainer = styled.article`
  background-color: ${(props) =>
    props.color === 'white' ? props.theme.colors.black : props.theme.colors.white};
  color: ${(props) =>
    props.color === 'white' ? props.theme.colors.white : props.theme.colors.black};
  padding: 1.5rem 2rem;
`;

export default BlogCard;
