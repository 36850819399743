import React from 'react';
import Img from 'gatsby-image';
import BackgroundImg from 'gatsby-background-image';
import { Container, Row, Col, Card } from 'react-bootstrap';
import styled from 'styled-components';

import { mediaQueries, breakpoints } from '../../theme/mediaQueries';

import SectionBgComponent from '../SectionBgComponent';
import PageSectionTitle from '../PageSectionTitle';
import YoutubeCarousel from '../Video/YoutubeCarousel';
import PageFooter from '../PageFooter';
import OutlineBtnLg from '../Buttons/OutlineBtnLg';

const VideosSection = ({ path, title, viewAll, videoNotAvailable }) => {
  return (
    <SectionBgComponent bg="black">
      <Container className="text-center pt-3 pt-md-5 pb-2">
        <PageSectionTitle title={title} />
      </Container>

      <StyledContainer>
        <YoutubeCarousel
          showIndicators={false}
          showArrows={true}
          videoNotAvailable={videoNotAvailable}
        />
      </StyledContainer>

      <PageFooter>
        <OutlineBtnLg btnText={viewAll} toUrl={`${path}videos`} type="primary" />
      </PageFooter>
    </SectionBgComponent>
  );
};

const StyledContainer = styled(Container)`
  @media screen and (max-width: ${breakpoints.md}px) {
    padding-left: 0 !important;
    padding-right: 0;
    max-width: none;
  }
`;

export default VideosSection;
