import React from 'react';
import styled from 'styled-components';

import SectionBgComponent from '../SectionBgComponent';
import GalleryComponent from '../Gallery/GalleryComponent';
import OutlineBtnLg from '../Buttons/OutlineBtnLg';

const GallerySection = ({ images, path, btnText }) => {
  return (
    <SectionBgComponent bg="black">
      <div style={{ position: 'relative' }}>
        <GalleryComponent images={images} itemsPerRow={3} />
        <StyledBtnOverlay>
          <OutlineBtnLg btnText={btnText} type="white" outline toUrl={`${path}gallery`} />
        </StyledBtnOverlay>
      </div>
    </SectionBgComponent>
  );
};

const StyledBtnOverlay = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.4);
`;

export default GallerySection;
