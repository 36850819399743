import React from 'react';
import { Link } from 'gatsby';
import { Card } from 'react-bootstrap';
import styled from 'styled-components';

import { mediaQueries } from '../../theme/mediaQueries';
import { localizeDate } from '../../lib/helpers';

import ViewMoreBtn from '../Buttons/ViewMoreBtn';
import PortableText from '../portableText';

const EventCard = ({ event, lang, relatedUrl, btnText }) => {
  const { slug, title, eventDate, location, _rawShortDescription: description } = event;
  const localeEventDate = localizeDate(eventDate, lang, 'long', true);
  // console.log(relatedUrl);

  return (
    <StyledLink to={relatedUrl ? `${relatedUrl}${slug.current}` : slug.current}>
      <StyledCard>
        <StyledCardHeader>
          <time>
            <strong>{localeEventDate}</strong>
          </time>
        </StyledCardHeader>
        <StyledCardBody>
          <StyledTitle>{title}</StyledTitle>
          <StyledCardSubtitle>{location}</StyledCardSubtitle>
          <StyledText>
            <PortableText blocks={description} />
          </StyledText>
        </StyledCardBody>
        <StyledCardFooter>
          <ViewMoreBtn btnText={btnText} />
        </StyledCardFooter>
      </StyledCard>
    </StyledLink>
  );
};

const StyledLink = styled(Link)`
  display: inline-block;
  &:hover,
  &:active {
    & > div {
      background-color: ${(props) => props.theme.colors.black};
      color: ${(props) => props.theme.colors.white};
    }
    & .card {
      border-color: ${(props) => props.theme.colors.black};
    }
    & .card-footer {
      & > span {
        color: ${(props) => props.theme.colors.primary};
        &::before {
          width: 56px;
        }
      }
    }
  }
`;

const StyledCard = styled(Card)`
  border-radius: 0;
  transition: all 0.3s ease;
  padding: 1.5rem 2rem;
`;

const StyledCardHeader = styled(Card.Header)`
  background-color: ${(props) => props.theme.colors.transparent};
  border-bottom: 0;
  border-radius: 0 !important;
  margin-bottom: 0;
  padding: 0;

  & > time {
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: 300;

    & > strong {
      font-weight: 500;
    }
  }
`;

const StyledCardBody = styled(Card.Body)`
  padding-left: 0;
  padding-right: 0;
  font-weight: 300;
  padding-top: 0.6rem;
`;

const StyledTitle = styled(Card.Title)`
  font-weight: 600;
  color: ${(props) => props.theme.colors.primary};
`;

const StyledCardSubtitle = styled(Card.Subtitle)`
  font-size: 0.9rem;
  font-weight: 400;
  color: ${(props) => props.theme.colors.primary};
  text-transform: uppercase;
  letter-spacing: 0.05em;
  margin-top: 0.625rem;
`;

const StyledText = styled.div`
  margin-top: 1.3rem;
`;

const StyledCardFooter = styled(Card.Footer)`
  background-color: ${(props) => props.theme.colors.transparent};
  border-top: 0;
  border-radius: 0 !important;
  padding: 0;
`;

export default EventCard;
