import React from 'react';
import { chunk, sum } from 'lodash';
import { Box } from 'rebass';
import Img from 'gatsby-image';

const GalleryComponent = ({ images, itemsPerRow }) => {
  const rows = chunk(images, itemsPerRow);
  // console.log(images);

  return (
    <Box style={{ display: 'flex', flexWrap: 'wrap' }}>
      {rows.map((row) => {
        const rowAspectRatioSum = sum(row.map((image) => image.portrait.asset.fluid.aspectRatio));

        return row.map((image) => {
          return (
            <Box
              key={image.id}
              title={image.caption}
              width={`${(image.portrait.asset.fluid.aspectRatio / rowAspectRatioSum) * 100}%`}
              css={{ display: 'inline-block' }}
            >
              <Img fluid={image.portrait.asset.fluid} alt={image.caption} />
            </Box>
          );
        });
      })}
    </Box>
  );
};

export default GalleryComponent;
