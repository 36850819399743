import React from 'react';
import styled from 'styled-components';

const SectionBgComponent = ({ children, bg }) => (
  <StyledSectionBg bg={bg}>{children}</StyledSectionBg>
);

const StyledSectionBg = styled.section`
  background-color: ${(props) =>
    props.bg && props.bg === 'black' ? props.theme.colors.black : props.theme.colors.white};
`;

export default SectionBgComponent;
