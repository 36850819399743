import React from 'react';
import { Container } from 'react-bootstrap';
import styled from 'styled-components';

import { breakpoints } from '../../theme/mediaQueries';

import SectionBgComponent from '../SectionBgComponent';
import PageSectionTitle from '../PageSectionTitle';
import BlogCard from '../Blog/BlogCard';
import OutlineBtnLg from '../Buttons/OutlineBtnLg';
import PageFooter from '../PageFooter';

const BlogSection = ({ title, blogposts, path, lang, viewAll, viewOne }) => {
  // console.log(blogposts);
  const blogCardList = blogposts.map((post) => (
    <BlogCard
      key={post.id}
      post={post}
      lang={lang}
      color="white"
      relatedUrl={lang === 'en' ? `/${lang}/blog/` : `/blog/`}
      btnText={viewOne}
    />
  ));
  return (
    <SectionBgComponent bg="black">
      <Container className="text-center pt-3 pt-md-5 pb-2">
        <PageSectionTitle title={title} />
      </Container>
      <Container>
        <StyledCardContainer>{blogCardList}</StyledCardContainer>
      </Container>
      <PageFooter>
        <OutlineBtnLg
          toUrl={lang === 'en' ? `/${lang}/blog/` : `/blog/`}
          btnText={viewAll}
          type="primary"
        />
      </PageFooter>
    </SectionBgComponent>
  );
};

const StyledCardContainer = styled.div`
  margin: 0;
  padding: 0;

  & .gatsby-image-wrapper {
    padding-bottom: 56.25%;
  }

  & > a:nth-child(even) {
    @media (min-width: ${breakpoints.md}px) {
      & .blog-card-left {
        order: 2;
      }
      & .blog-card-right {
        order: 1;
        /* transform: translate(3rem, 3rem); */
      }
    }
  }
`;

const StyledCardWrapper = styled.div`
  /* margin-bottom: 2rem; */

  @media (min-width: ${breakpoints.md}px) {
    /* margin-bottom: 3rem; */
  }
`;

export default BlogSection;
