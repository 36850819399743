import React from "react";
import BackgroundImg from "gatsby-background-image";
import styled from "styled-components";

const BlurredBgSection = ({ fluid, children }) => (
  <StyledBackground>
    <StyledBackgroundImg fluid={fluid}>
      <StyledOverlay>{children}</StyledOverlay>
    </StyledBackgroundImg>
  </StyledBackground>
);

const StyledBackground = styled.div`
  background-color: black;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  overflow: hidden;
`;

const StyledBackgroundImg = styled(BackgroundImg)`
  width: 100%;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;

  &::before,
  &::after {
    filter: blur(30px);
  }
`;

const StyledOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${(props) => props.theme.colors.black};
  background: ${(props) => props.theme.gradients.linearOverlay};
`;

export default BlurredBgSection;
